
import { 
  NavLink,
  Outlet,
} from "react-router-dom";

function NavigationLayout(props) {
  return (
    <>

      <nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <NavLink to="/uploadfile">Upload File</NavLink>
        <NavLink to="/timer">Timer</NavLink>
        <NavLink to="/forms">Forms</NavLink>
        <NavLink to="/register">Broken Register Link</NavLink>
      </nav>

      <main className='body-main'>
        <Outlet />
      </main>
    </>
  )
};

export default NavigationLayout;