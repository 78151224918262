import React from 'react';
import { uploadData } from 'aws-amplify/storage';
import { list } from 'aws-amplify/storage';
import { useState, useEffect } from 'react';

import '../App.css';

function UploadFile(props) {

  const [file, setFile] = React.useState();

  // const handleChange = (event: any) => {
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };


  useEffect(() => {

    console.log("useEffect");

    showFiles();
    return () => {

      // connection.disconnect();
    };



  }, []);

  async function showFiles() {
    try {
      const response = await list({
        path: 'public/',
        // Alternatively, path: ({identityId}) => `protected/${identityId}/photos/`
        // path: ({identityId}) => `protected/${identityId}/`
        options: {
          listAll: true
        }
      });

            // render list items from response.items
      let result = [];

      for (let i = 1; i < response.items.length; i++)
      {
        result.push(response.items[i]);
      }
    
      for (let i = 0; i < result.length; i++)
      {
        console.log(result[i].path);
      }

      // render list items from response.items
    } catch (error) {
      console.log('ReactJS Error ', error);
    }

  }


  return (
    <>
      <div>
        <input type="file" onChange={handleChange} />
        <button
          onClick={() =>
            uploadData({
              // path: `uploads/${file.name}`,
              path: `public/${file.name}`,
              data: file,
            })
          }
        >
          Upload
        </button>
      </div>
    </>
  );
}

export default UploadFile;