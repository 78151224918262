
import '../App.css';


import * as React from 'react';
import { useState, useEffect, useRef } from 'react';


import android_1 from '../images/android_1.png';
import android_2 from '../images/android_2.png';
import ios_1 from '../images/iphone_se.png';

function Timer(props) {


    const [clockValue, setClockValue] = useState(0);
    const count = useRef(0);

    useEffect(() => {

        /*
        let timer = setTimeout(function () {

            count.current = count.current + 1;
            setClockValue(count.current)
            // console.log(count.current);
            // console.log("Use effect");

            
            // const connection = createConnection(serverUrl, roomId);
            // connection.connect();
            

        }, 5000);
        */

        // bug in here???? off by one??? 
        if (clockValue === 0) {
            showSlides();
        }

        let timer = setTimeout(showSlides, 2000);

        // clean up
        return () => {
      
            clearTimeout(timer);
            // connection.disconnect();
        };



    }, [clockValue]);



    function showSlides() {

        let i;
        let slides = document.getElementsByClassName("mySlides");

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        /*
        count.current = count.current + 1;

        if (count.current > 3) {
            count.current = 1;
        }

        else if (count.current < 1) {
            count.current = 3;
        }

        slides[count.current - 1].style.display = "block";
        */

        if (count.current < 0)
        {
            count.current = slides.length - 1;
        }

        else if (count.current >= slides.length)
        {
            count.current = 0;
        }

        // console.log(count.current);


        slides[count.current].style.display = "block";

        count.current = count.current + 1;


        /*
        slideIndex++;
        if (slideIndex > slides.length) { slideIndex = 1 }
        slides[slideIndex - 1].style.display = "block";
        */

        /* slides[count.current % 3].style.display = "block"; */








        // count.current = count.current + 1;
        setClockValue(count.current)
    }

    function handleClickSlideNextPicture() {
  
        showSlides();
    }

    function handleClickSlidePreviousPicture() {
    
        count.current = count.current - 2;

        showSlides();
    }

    return (
        <>
            
            
            <h1>Render Count: {clockValue}</h1>
       
            <div className="mySlides">
                <img src={android_1} alt="android_1" />

            </div>

            <div className="mySlides">
                <img src={android_2} alt="android_2" />

            </div>

            
            <div className="mySlides">
                <img src={ios_1} alt="ios" />

            </div>
    

            
            <div>
                <button onClick={handleClickSlidePreviousPicture}>
                    Previous Picture
                </button>


                <button onClick={handleClickSlideNextPicture}>
                    Next Picture
                </button>
            </div>
            



        </>
    );
}

export default Timer;

