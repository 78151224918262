import '../App.css';

function Home(props) 
{
    return(
        <>
        <div>
            <h1>Welcome to my web site!!</h1>
        </div>
        </>
    );
}

export default Home;