import '../App.css';

import * as React from 'react';
import { useState, useRef } from 'react';

import { Outlet } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';


function ProtectedRoutes(props) {

  const authenticated = useRef(0);
  const [userName, setUserName] = useState("");

  React.useEffect(() => {

    currentAuthenticatedUser();

    return () => {

      // connection.disconnect();
    };
  }, [])

  async function currentAuthenticatedUser() {
    try {

      const { username, userId, signInDetails } = await getCurrentUser();
      /*
      console.log({username});
      console.log({userId});
      console.log({signInDetails});
      */

      authenticated.current = 1;
      // console.log(authenticated);
      setUserName(username);

      

    } catch (e) {
      authenticated.current = 0;
      setUserName("");
    
      console.log(e);
    }
  }

  function displayOutlet()
  {
    if (userName === "roberto")
    {
      return <Outlet />
    }
  }
 
  return (
    <>
        { displayOutlet() }
    </>
  );
}

export default ProtectedRoutes;