import { getCurrentUser } from 'aws-amplify/auth';

import '../App.css';

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useRef } from 'react';

import {
  Menu, MenuItem, Divider, Message, Alert, Loader, Input, Label, TextField, Text
} from '@aws-amplify/ui-react';


import { Button } from '@aws-amplify/ui-react';

import { post } from 'aws-amplify/api';


function Forms(props) {

  const [inputs, setInputs] = useState({});
  const [httpStatusCode, setHttpStatusCode] = useState(0);
  const [httpErrorMessage, setHttpErrorMessage] = useState(null);

  const [outputMessage, setOutputMessage] = useState(null);



  const [hasError, setHasError] = React.useState(true);

  const validateUsername = (e) => {
    const pattern = /\d/;
    const containsDigit = pattern.test(e.currentTarget.value);
    setHasError(!containsDigit);
    console.log(containsDigit);

  };






  const myRef = useRef(null);

  async function postTodo() {
    try {
      /*
      const todo = { name: 'My first todo', message: 'Hello world!' };

      console.log(todo);
      console.log(inputs);
      */

      // debugger;

      // reset statusCode before calling api
      // so useEffect can work properly
      setHttpStatusCode(0);


      // reset error message before calling api
      // simply to keep it clean
      setHttpErrorMessage(null);


      const restOperation = post({
        apiName: 'cloudSystemDesignApi',
        path: '/ai',
        options: {
          /*
          body: {
            message: 'Mow the lawn'
          }
          */
          body: { inputs }
        }
      });


      // get response body
      const { body } = await restOperation.response;
      const response = await body.json();

      setOutputMessage(response);

      // get status code
      const httpCode = await restOperation.response;

      // set status code to success
      setHttpStatusCode(httpCode.statusCode);

      // console.log(response);
      // console.log(httpCode.statusCode);



      // console.log('POST call succeeded');


      // reset input form data to nothing
      setInputs({});





    } catch (e) {
      // console.log('POST call failed: ', e);

      // extract and convert error message of type string to type object
      const errorMessageObj = JSON.parse(e.response.body);


      // console.log(errorMessageObj["message"]);

      setHttpStatusCode(e.response.statusCode);
      setHttpErrorMessage(errorMessageObj["message"]);
    }
  }

  const validateTesting = (testing) => {

    if (testing != null)
    {
      console.log(testing.length);
    }
    // const pattern = /\d/; // contains at least one digit
    const pattern = /^.{1,5}$/; // not null to 5 [1,5]
    const containsDigit = pattern.test(testing);
    
    // console.log(containsDigit);

    return containsDigit;
  }


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))


    // crear error or success message from previous action
    setHttpStatusCode(0);

    console.log(validateTesting(myRef.current.value));
    setHasError(!validateTesting(myRef.current.value));
    console.log(myRef.current.value);
    console.log(inputs);

  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // const value = myRef.current.value;

  
    if (hasError)
    {

      myRef.current.focus();
      return;
    }
    /*
    if (validateTesting (inputs.testing) === false) 
    {
      alert("Testing no good");
      return;
    }

    console.log(inputs);
    */
    


    postTodo();
    // alert(inputs.age);
  }


  const didPostSuccedd = () => {

    if (httpStatusCode === 200) {




      return <div className='test'>

        <Message
          isDismissible="true"
          variation="filled"
          colorTheme="success"
          heading="Success">
          GenAI:  {outputMessage}
        </Message>

      </div>

    }

    else if (httpStatusCode === 502) {


      /// myRef.current.style.backgroundColor = "red";
      return <div className='test'>
        <Message
          isDismissible="true"
          variation="filled"
          colorTheme="error"
          heading="Error">
          {httpErrorMessage}
        </Message>
      </div>
    }
  }


  async function currentAuthenticatedUser(){
    try{
      const {username,userId, signInDetails} = await getCurrentUser();
      console.log({username});
      console.log({userId});
      console.log({signInDetails});
    } catch(e){
      console.log(e);
    }
  }



  // renders react page only when httpStatusCode changes
  React.useEffect(function () {
  

  }, [httpStatusCode]);


  return (
    <>
      <br />
      <br />
      <button
        onClick={currentAuthenticatedUser}>
        TEST
      </button>
    

      <form className={'input-form'} onSubmit={handleSubmit}>
        <Label className="custom-label-class">Prompt:
          <Input className='custom-input-class'
            type="text"
            name="username"
            isRequired
            value={inputs.username || ""}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label className="custom-label-class">Enter your age:
          <Input className='custom-input-class'
            type="number"
            name="age"
            value={inputs.age || ""}
            onChange={handleChange}
          />
        </Label>
        <br />

        <Label htmlFor="departing">Enter departing date ✈️</Label>
        <Input
          id="departing"
          type="date"
          name="dateCreated"
          value={inputs.dateCreated || ""}
          required
          onChange={handleChange}
        />

        <TextField className='custom-input-class'
          descriptiveText="Enter a valid zip code"
          placeholder="Enter your zip code"
          label="Zip code"
      
          hasError={hasError}
          errorMessage="Invalid zip code"
          name="zipCode"
        
          value={inputs.zipCode || ""}
          onChange={handleChange}
          ref={myRef}

        />
      


        <Button className="custom-button-submit-class" type="submit">Submit</Button>

        {hasError}

      </form>
      <br />
      {/*
      <div className={"test"} ref={myRef}></div>
      
      <button
        onClick={() => {
          myRef.current.style.backgroundColor ="yellow";
        }}>
        TEST
      </button>
      */}


      {didPostSuccedd()}




    </>

  );


}

export default Forms;

// beautify -> Option - shift - F