/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9168831e-89c9-4227-870a-bd4752b8dc19",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EmXQF2MSt",
    "aws_user_pools_web_client_id": "4i0ph4n6t1seeeifbbj8c6siuj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "cloudSystemDesignApi",
            "endpoint": "https://13g30zmtz3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "messagesamp-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "cloudsystemdesign-amp-hai-bucketa7d50-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
