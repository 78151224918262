
/*

import './App.css';

function App() {
  return (
    <div className="App">
      <p>Hello World!!!</p>
    </div>
  );
}

export default App;

*/



import './App.css';
import * as React from 'react';


import Home from "./components/Home.jsx";
import UploadFile from "./components/UploadFile.jsx";
import Contact from "./components/Contact.jsx";
import ProtectedRoutes from "./components/ProtectedRoutes.jsx";

import Timer from './components/Timer.jsx';

import NoPage from "./components/NoPage.jsx";
import NavigationLayout from "./components/NavigationLayout.jsx";
import Forms from "./components/Forms.jsx";

import home from "./images/house.svg"

import { Amplify } from 'aws-amplify';



import {
  useTheme,
  View,
  Text,
  Button,
  Image,
} from '@aws-amplify/ui-react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";


import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization

const components = {


  
  Header() {
    const { tokens } = useTheme();

    return (
      <>
      <br />
   
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Cloud System Design Logo"
          
          src={home}
        />
      </View>
      {/* 
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="https://docs.amplify.aws/assets/logo-dark.svg"
        />
      </View>
      */}
     </>
    );
  },
  


  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          cloudsystemdesign.com &copy;2024
        </Text>
      </View>
    );
  },

  /*
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  SetupTotp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  */
};


const formFields = {
  setupTotp: {
    QR: {
      totpIssuer: 'CloudSystemDesign',
      /* totpUsername: 'amplify_qr_test_user', */
    },
  },
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<NavigationLayout />}>

      <Route path="/" element={<Home />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="/contact" element={<Contact />} />
        <Route path="/uploadfile" element={<UploadFile />} />
      </Route>
      <Route path="/forms" element={<Forms/>} />
      <Route path="/timer" element={<Timer />} />


      <Route path="/*" element={<NoPage />} />
    </Route>
  )
);


export default function App() {


  return (
    <>
      <Authenticator components={components} formFields={formFields} hideSignUp={true}>

        {({ signOut, user }) => (
          <>
            <div>

              <h1>Hello {user.username}</h1>

              <Button variation="primary" colorTheme="info" onClick={signOut}>Sign out</Button>

              <RouterProvider router={router} />
            </div>
          </>
        )}


      </Authenticator>
    </>
  );
}


/*
https://docs.amplify.aws/javascript/build-a-backend/restapi/fetch-data/
JavaScript v6

https://react-icons.github.io/react-icons/

https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/

// deprecated
"@babel/plugin-proposal-private-property-in-object":"^7.21.11",

*/

